<template>
    <i v-if="iconTarget"        class="px-1 bi" :class="iconClass" v-target:[iconTarget] role="button"></i>
    <i v-else-if="iconCallback" class="px-1 bi" :class="iconClass" @click="iconCallback" role="button"></i>
    <i v-else                   class="px-1 bi" :class="iconClass"></i>
</template>

<script setup lang="ts">
    const props = defineProps({        
        iconClass: {
            type: String,
            required: true
        },

        iconTarget: {
            type: String,
            required: false
        },

        iconCallback: {
            type: Function,
            required: false
        }
    });

    /* <!-- Usage example: -->
    import Icon from "contract.vue.components.ActionIcon.vue";

    <!-- To open a modal by click: -->
    <Icon iconClass="bi-three-dots" iconTarget="modalNameToOpen"/>

    <!-- Call a function by click: -->
    <Icon iconClass="bi-three-dots" iconCallback="functionToCall"/>
    */


    /* <!-- FAQ: -->
    Why to use ":iconCallback" and "iconTarget" instead of "@click" and "v-target"?
    - element will automatically get a right cursor as it's clickable

    Can I use "class" instead of "iconClass"?
    - Yes, it's just a semantic to visually separate classes for the icon and for other needs.
    - You can use both at the same time, it will work fine.

    Why not to add class to a different element instead of using this component.
    - This components requires less tuning to look good.
    - It works perfect with a grid cells. Check "CellWithIcon" component. (contract.vue.components.CellWithIcon.vue)
    */
</script>